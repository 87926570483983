import React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { Link } from 'gatsby';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Card from '@bit/medicalwebexperts.mwe-ui.card';
import Button from '@bit/medicalwebexperts.mwe-ui.button';
import Image from '@bit/medicalwebexperts.mwe-ui.image';
import SEO from '../components/Seo/Seo';
import successImg from '../images/success.png';
import successImg2x from '../images/success@2x.png';

const Main = styled(Card)(
  css({
    borderRadius: '20px',
    boxShadow: '0 0 26px rgba(0,0,0,.1)',
    position: 'relative',
    px: [2, null, null, 6],
    zIndex: 'docked',
  }),
);

const ImageWrapper = styled.div(
  css({
    mx: 'auto',
    my: 10,
    width: 'fit-content',
  }),
);

const TextWrapper = styled.div(
  css({
    maxWidth: '400px',
    mb: 8,
    mx: 'auto',
    textAlign: 'center',
  }),
);

const ThankYouPage = () => (
  <Flex justifyContent="center">
    <SEO title="Your Application was successfully sent" />
    <Main>
      <Card.Body py={12}>
        <Typography variant="h1" color="secondary.main" textAlign="center">
          Travel Health Declaration <strong>successfully sent</strong>
        </Typography>
        <ImageWrapper>
          <Image
            src={successImg}
            srcSet={`${successImg} 1x, ${successImg2x} 2x`}
            alt="Success"
            responsive
          />
        </ImageWrapper>
        <TextWrapper>
          <Typography>
            You will receive an email shortly with a link to our Portal where you must upload the required documentation to complete the process.
          </Typography>
        </TextWrapper>
        <Button as={Link} to="/" variant="text" variantColor="primary" block>
          Back
        </Button>
      </Card.Body>
    </Main>
  </Flex>
);

export default ThankYouPage;
